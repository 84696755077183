<template>
  <div class="video_detail_page">
    <my-header isShowLogo="hide" v-if="isShowLogo"></my-header>
    <div class="video_box">
      <video
        id="kv_video"
        ref="videoRef"
        autoplay
        loop
        controls
        webkit-playsinline="true"
        playsinline="true"
        x5-playsinline="true"
        x5-video-player-type="h5-page"
        controlslist="nodownload noremoteplayback noplaybackrate"
        disablePictureInPicture
        @touchend="clickEvent"
      >
        <source :src="videoSrc" type="video/mp4" />
      </video>
    </div>
    <div
      class="cover_box"
      id="playIcon"
      @click="playVideo"
      v-if="isShowPauseCover"
    >
      <figure class="play_icon">
        <img src="../assets/images/video/play_icon1.png" />
      </figure>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/MyHeader.vue";
export default {
  components: {
    MyHeader,
  },
  props: {},
  data() {
    return {
      videoSrc: "",
      isShowLogo: true,
      isShowPauseCover: false,
      timer: null,
    };
  },
  async created() {
    const query = JSON.parse(JSON.stringify(this.$route.query));
    this.videoSrc = query.video;
  },
  mounted() {
    const video = document.getElementById("kv_video");
    this.$refs.videoRef.addEventListener("play", this.playVideo);
    this.$refs.videoRef.addEventListener("pause", this.pauseVideo);
    this.hideEvent();
  },
  computed: {},
  methods: {
    playVideo() {
      this.isShowPauseCover = false;
      this.hideEvent();
      const video = document.getElementById("kv_video");
      video.play();
    },
    pauseVideo() {
      this.isShowPauseCover = true;
      const video = document.getElementById("kv_video");
      video.pause();
      this.isShowLogo = true;
      // console.log("暂停视频");
    },

    clickEvent(event) {
      // event.preventDefault();
      this.isShowLogo = true;
      this.hideEvent();
      // console.log("阻止视频默认点击事件", this.isShowLogo);
    },
    hideEvent() {
      // 清除定时器
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isShowLogo = false;
      }, 3000);
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_fun.scss";
.video_detail_page {
  position: relative;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
  overflow: hidden;

  .video_box {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 99;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cover_box {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    cursor: pointer;
    overflow: hidden;

    .play_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
    }
  }

  //全屏按钮
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
}
</style>
